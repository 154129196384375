import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';
import Gallery from '../components/Gallery';

import gavin from '../assets/images/gavin.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead" />

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">Hi, I'm Gavin...</h2>
            <p className="text-white-50">
              I'm a Melbourne-based photographer specialising in automotive and landscape photography.
              <br /><br />
              My passion for photography was ignited at a young age when I purchased my first film camera to take away on school camp, and over the years inspired by my grandfather who was an avid photographer. Since then, I've worked with various clients, including both individuals and well-known household names.
              <br /><br />
              Thank you for taking the time to view my website, gallery of work, or even purchasing one of my images.
            </p>
          </div>
        </div>
        <img src={gavin} className="img-fluid" style={{ width: '25%', borderRadius: '100%', border: 'solid 5px #fff', marginBottom: '-100px' }} alt="" />
      </div>
    </section>

    <section id="projects" className="projects-section bg-light">
      <div style={{ padding: '0 20px' }}>
        <Gallery />
      </div>
    </section>

    <section id="contact">
      <SocialLinks />
    </section>
    <Footer />
  </Layout>
);

export default IndexPage;
