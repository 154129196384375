const breakpoints = [3840, 2400, 1280, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id, width, height) =>
    `/portfolio/${id}.jpg`;

const unsplashPhotos = [
    { id: "DSC_3533", width: 1280, height: 848 },
    { id: "DSC01939", width: 1280, height: 290 },
    { id: "DSC_1460", width: 1280, height: 854 },
    { id: "DSC_1612", width: 1280, height: 848 },
    { id: "DSC_1725", width: 1280, height: 848 },
    { id: "DSC_2175", width: 1280, height: 848 },
    { id: "DSC_2469", width: 1280, height: 843 },
    { id: "DSC_1448", width: 1280, height: 854 },
    { id: "DSC_2652", width: 1280, height: 848 },
    { id: "DSC_1443", width: 1280, height: 854 },
    { id: "DSC_4014", width: 1280, height: 848 },
    { id: "DSC_6136", width: 1280, height: 848 },
    { id: "DSC_6585", width: 1280, height: 848 },
    { id: "DSC_6714", width: 1280, height: 848 },
    { id: "DSC_1463", width: 1280, height: 854 },
    { id: "DSC_6803", width: 1280, height: 850 },
    { id: "DSC_2511", width: 1280, height: 848 },
    { id: "DSC_6878", width: 1280, height: 850 },
    { id: "DSC_6954", width: 1280, height: 850 },
    { id: "DSC_6995", width: 1280, height: 848 },
    { id: "DSC_7219", width: 1280, height: 848 },
    { id: "DSC_8924", width: 1280, height: 848 },
    { id: "DSC_1335", width: 1280, height: 848 },
    { id: "DSC_9017", width: 1280, height: 848 },
    { id: "DSC_9487", width: 1280, height: 848 },
    { id: "DSC01084", width: 1280, height: 290 },
    { id: "DSC01983", width: 851, height: 1280 },
    { id: "DSC_7063", width: 1280, height: 850 },
    { id: "DSC_6722", width: 1280, height: 850 },
];

const photos = unsplashPhotos.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: unsplashLink(photo.id, width, height),
        width,
        height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: unsplashLink(photo.id, breakpoint, height),
                width: breakpoint,
                height,
            };
        }),
    };
});

export default photos;
